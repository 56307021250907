
import { Component, Vue } from 'vue-property-decorator'
import { DotationOperation } from '@/models'

import OperationsList from './components/OperationsList.vue'
import OperationReport from './components/OperationReport.vue'

import { clipboardSuccess, handleClipboard } from '@/utils/clipboard'

@Component({
  name: 'OperationsWrapper',
  components: {
    OperationsList,
    OperationReport,
    MultiChoiceCardsTable: () => import('./components/MultiChoiceCardsTable.vue')
  }
})
export default class extends Vue {
  private selectedOperation: DotationOperation | null = null
  private selectedOperationIndex: number | null = null

  private tooltipDelete = false
  private operationsLoading = true
  private clipboardSuccess = clipboardSuccess
  private textCSE: any = {
    no_cse: 'Aucun CSE',
    social_cse: 'Social CSE',
    budget_cse: 'Budget CSE'
  }

  get durationYears(): number {
    return this.$moment.duration(this.selectedOperation!.dotationDuration).years()
  }

  get durationMonths(): number {
    return this.$moment.duration(this.selectedOperation!.dotationDuration).months()
  }

  get durationDays(): number {
    return this.$moment.duration(this.selectedOperation!.dotationDuration).days()
  }

  private copyOperationId($event: any): void {
    handleClipboard(this.selectedOperation?.id || 'Unknown ID', $event)
  }

  private selectOperation({ operation, index }: any): void {
    this.selectedOperation = operation.dup()
    this.selectedOperationIndex = index
  }

  private editOperation(): void {
    ;(this.$refs.operationsList as OperationsList).openUpdateDialog()
  }

  private setOperationWithBatch(data: any): void {
    this.selectedOperation!.bid = data.bid
    this.selectedOperation!.batchInProcess = data.batch_in_process
    ;(this.$refs.operationsList as OperationsList).updateInList(this.selectedOperation as DotationOperation)
  }

  async refresh(): Promise<void> {
    const { data } = await DotationOperation.find(this.selectedOperation!.id)

    await (this.$refs.operationReport as OperationReport).fetchDotationOperationReports()

    this.selectedOperation = data;
    (this.$refs.operationsList as OperationsList).updateInList(this.selectedOperation as DotationOperation)
  }

  async handleDelete() {
    try {
      await this.selectedOperation!.destroy()

      ;(this.$refs.operationsList as OperationsList).deleteOperation(this.selectedOperationIndex as number)
      this.tooltipDelete = false
      this.selectedOperation = null

      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
    } catch (e) {
      this.$notify({
        title: 'Error',
        message: 'Delete error',
        type: 'error',
        duration: 2000
      })
    }
  }
}
