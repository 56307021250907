
import { Component, Vue } from 'vue-property-decorator'
import UploadExcel from '@/components/UploadExcel/index.vue'
import { FileMapper } from '@/models'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'FileMapper',
  components: {
    CreateFileMapperDialog: () => import('./components/CreateFileMapperDialog.vue'),
    UploadExcel
  }
})
export default class extends Vue {
  private fileMappers: FileMapper[] = []
  private fileMappersLoading = false
  private fileMapperEdit: any = null
  private tableHeader: string[] = []
  private tableData: any = []
  createFileMapper = false

  created() {
    this.getFileMappers()
  }

  private async getFileMappers() {
    this.fileMappersLoading = true
    const { data } = await FileMapper.order({ createdAt: 'desc' }).all()
    this.fileMappers = data
    this.fileMappersLoading = false
  }

  private async handleDelete(data: FileMapper, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.fileMappers.splice(index, 1)
    }
  }

  private beforeUpload(file: File) {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (isLt1M) {
      return true
    }
    this.$message({
      message: 'Please do not upload files larger than 1m in size.',
      type: 'warning'
    })
    return false
  }

  private handleUpdate(data: FileMapper) {
    this.fileMapperEdit = data
    this.tableHeader = []
    this.tableData = []
    this.createFileMapper = true
  }

  private handleUpdateTable(data: FileMapper) {
    const index = this.fileMappers.findIndex(v => v.id === data.id)
    this.fileMappers.splice(index, 1, data)
    this.fileMapperEdit = null
    this.createFileMapper = false
  }

  private handleSuccess(data: FileMapper) {
    this.fileMappers.push(data)
    this.tableHeader = []
    this.tableData = []
    this.createFileMapper = false
  }

  private handleSuccessUpload({ results, header }: { results: any, header: string[]}) {
    this.tableData = results.slice(0, 2)
    this.tableHeader = header
    this.createFileMapper = true
  }

  private handleClose() {
    this.fileMapperEdit = null
    this.tableHeader = []
    this.tableData = []
    this.createFileMapper = false
  }

  private printErrors(errors: any) {
    const e: (IValidationError<FileMapper> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}
