
import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileMapper } from '@/models'
import { Form as ElForm } from 'element-ui/types/element-ui'

@Component({
  name: 'CreateFileMapperDialog'
})
export default class extends Vue {
  @Prop({ required: false, default: null }) private headers!: any
  @Prop({ required: false, default: null }) private tableData!: any
  @Prop({ required: false, default: null }) private fileMapperEdit!: FileMapper
  @Prop({ required: true }) private visible!: boolean

  loadingCreate = false
  private fileMapper: FileMapper = new FileMapper({
    fields: {
      code: '',
      amount: '',
      pin_code: '',
      barcode: '',
      external_pdf_url: '',
      expire_at: '',
      technical_expire_at: ''
    },
    charDelimiter: ',',
    dateFormat: '%Y %m %d',
    headers: true
  })

  rules: any = {
    'fields.code': [
      { required: true, message: 'La variable code doit être assigné', trigger: 'blur' }
    ],
    'fields.amount': [
      { required: true, message: 'La variable montant doit être assigné', trigger: 'blur' }
    ]
  }

  created() {
    if (this.fileMapperEdit) this.fileMapper = this.fileMapperEdit
  }

  private createOrUpdate() {
    (this.$refs.fileMapperForm as ElForm).validate(async(valid) => {
      this.loadingCreate = true

      if (valid) {
        try {
          for (const key in this.fileMapper.fields) this.fileMapper.fields[key] === '' && delete this.fileMapper.fields[key]

          await this.fileMapper.save()

          if (!this.fileMapperEdit) {
            this.$emit('success', this.fileMapper)
            this.$notify({
              title: 'File Mapper created',
              message: 'File Mapper successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', this.fileMapper)
            this.$notify({
              title: 'File Mapper updated',
              message: 'File Mapper successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        } catch (err) {
          this.loadingCreate = false
        }
      }
      this.loadingCreate = false
      this.$emit('close')
    })
  }
}
