
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'GiftsListFilters'
})
export default class extends Vue {
  filters: any = {
    email: null,
    firstName: null,
    lastName: null,
    dateRange: null,
    status: null
  }
  pagination = {
    page: 1,
    limit: 10
  }

  statusOptions = [
    { label: 'Ouvert', value: 'opened' },
    { label: 'Délivré', value: 'delivered' },
    { label: 'En préparation', value: 'in_preparation' }
  ]

  handleSearch() {
    this.$emit('filters', this.filters)
  }
}
