
import { Component, Vue } from 'vue-property-decorator'

import Pagination from '@/components/Pagination/index.vue'
import { Card, Gift } from '@/models'

@Component({
  name: 'GiftsList',
  components: {
    GiftsListFilters: () => import('./components/GiftsListFilters.vue'),
    Pagination
  }
})
export default class extends Vue {
  gifts: Gift[] = []
  giftLoading = true
  totalGifts = 0
  headersByDate: any[] = []

  filters: any = {
    email: null,
    firstName: null,
    lastName: null,
    dateRange: null,
    status: null
  }
  pagination = {
    page: 1,
    limit: 20
  }

  created() {
    this.getGifts()
  }

  private async getGifts() {
    this.giftLoading = true

    const createdAtGte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![0])).format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![1])).format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } = await Gift
        .includes([{ cards: ['giftCard'] }, 'recipient'])
        .where({
          recipientEmail: { prefix: this.filters.email },
          recipientFirstname: { prefix: this.filters.firstName },
          recipientLastname: { prefix: this.filters.lastName },
          status: this.filters.status,
          createdAt: { gte: createdAtGte, lte: createdAtLte }
        })
        .order({ createdAt: 'desc' })
        .page(this.pagination.page)
        .per(this.pagination.limit)
        .stats({ total: 'count' })
        .all()

    this.gifts = data
    this.totalGifts = meta.stats.total.count
    this.giftPerDate()
    this.giftLoading = false
  }

  private giftPerDate() {
    this.headersByDate = []
    this.gifts.map((e, index) => {
      if (this.gifts[index - 1] && this.$moment(this.gifts[index - 1].createdAt).format('L') !== this.$moment(e.createdAt).format('L')) {
        this.headersByDate.push(index)
      }
    })
  }

  handleSearch(filters: any): void {
    this.filters = filters
    this.pagination.page = 1
    this.gifts = []
    this.getGifts()
  }

  private cardInGift(cards: Card[]): any {
    const tempList: any = {}

    cards.forEach((card: any) => {
      const cardCharacteristic = `${card.giftCard.id}_${card.amount}`
      if (!tempList[cardCharacteristic]) tempList[cardCharacteristic] = [card]
      else tempList[cardCharacteristic].push(card)
    })

    return tempList
  }

  private writeCardDetails(cards: Card[]): any {
    if (cards.length === 0) return 'N/A'
    return `${cards.length} carte${cards.length > 1 ? 's' : ''} ${cards[0].giftCard.name} d'un montant égal à ${this.$n(cards[0].amount, 'currency')}`
  }
}
