
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DotationOperation, DotationOperationReport } from '@/models/index'

@Component({
  name: 'OperationReport'
})
export default class extends Vue {
  @Prop({ required: true }) private operation!: DotationOperation

  private reports: DotationOperationReport[] = []
  private reportsDetails: any[] = []
  private seeMoreDetails = false

  private detailsTab = null

  private pollingInterval: any = null
  private pollingData: any = null

  get totalIssuedCount() {
    return this.reports.reduce((acc, cur) => cur.issuedCount + acc, 0)
  }

  get totalGivenCount() {
    return this.reports.reduce((acc, cur) => cur.givenCount + acc, 0)
  }

  get totalExpiredCount() {
    return this.reports.reduce((acc, cur) => cur.expiredCount + acc, 0)
  }

  @Watch('operation.id', { immediate: true })
  async fetchDotationOperationReports() {
    const { data } = await DotationOperationReport
      .where({ dotationOperationId: this.operation.id })
      .includes('multiChoiceGiftCard')
      .all()

    this.reports = [...data]


    const tempList: any = {}
    data.forEach((report: DotationOperationReport) => {
      const multiChoiceGiftCardId = report.multiChoiceGiftCard.id
      if (!tempList[multiChoiceGiftCardId]) tempList[multiChoiceGiftCardId] = { name: report.multiChoiceGiftCard.name, list: [report] }
      else tempList[multiChoiceGiftCardId].list.push(report)
    })

    this.reportsDetails = Object.values(tempList)
  }

  @Watch('operation.batchInProcess', { immediate: true })
  async launchPolling(batchInProcess: boolean): Promise<void> {
    this.pollingData = null

    if (batchInProcess) {
      this.pollingData = await this.operation.pollBatchStatus()

      this.pollingInterval = setInterval(async () => {
        this.pollingData = await this.operation.pollBatchStatus()
      }, 5000)
    }
  }

  pollBatchPercentage(data: any): number {
    if (data?.is_complete) {
      clearInterval(this.pollingInterval)
      this.pollingData = null
      this.$emit('batchDone')
    }

    if (data?.total) return Math.round(Math.abs(((data.pending - data.total) / data.total) * 100))
    else return 0
  }

  formatPercentage(percentage: number): string {
    return `${percentage}% ${this.pollingData?.total > 0 ? `(${this.pollingData.total - this.pollingData.pending} / ${this.pollingData.total} cartes)` : ''}`;
  }

  issuedCountByMultiChoiceGiftCard(reports: DotationOperationReport[]): number {
    return reports.reduce((acc, cur) => cur.issuedCount + acc, 0)
  }

  issuedAmountByMultiChoiceGiftCard(reports: DotationOperationReport[]): number {
    return reports.reduce((acc, cur) => (cur.issuedCount * cur.amount) + acc, 0)
  }

  givenCountByMultiChoiceGiftCard(reports: DotationOperationReport[]): number {
    return reports.reduce((acc, cur) => cur.givenCount + acc, 0)
  }

  givenAmountByMultiChoiceGiftCard(reports: DotationOperationReport[]): number {
    return reports.reduce((acc, cur) => (cur.givenCount * cur.amount) + acc, 0)
  }
}
