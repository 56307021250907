
import { Component, Vue } from 'vue-property-decorator'
import { Budget } from '@/models'
import BudgetMove from '../../../models/BudgetMove'

@Component({
  name: 'GiftsHeader'
})
export default class extends Vue {
  private selectedMonth = new Date()
  private debsLoading = false
  private totalDebs = 0

  private budget: Budget | null = null
  private budgetLoading = false

  created(): void {
    this.getDebs()
    this.getBudget()
  }

  private async getDebs() {
    this.debsLoading = true
    const createdAtGte = this.selectedMonth ? this.$moment(String(this.selectedMonth)).startOf('month').format() : ''
    const createdAtLte = this.selectedMonth ? this.$moment(String(this.selectedMonth)).endOf('month').format() : ''

    const { data } =
        await BudgetMove
            .where({
              sourceType: 'MultiChoiceCard',
              createdAt: { gte: encodeURIComponent(createdAtGte), lte: encodeURIComponent(createdAtLte) }
            })
            .page(1)
            .per(999)
            .order({ createdAt: 'desc' })
            .all()

    this.totalDebs = data.reduce((acc: number, budgetMove: BudgetMove): number => acc + Math.abs(budgetMove.amount), 0)
    this.debsLoading = false
  }

  private async getBudget() {
    this.budgetLoading = true

    const { data } = await Budget.first()

    this.budget = data
    this.budgetLoading = false

  }
}
