
import { Component, Vue } from 'vue-property-decorator'
import { DotationOperation } from '@/models'

@Component({
  name: 'OperationsList',
  components: {
    CardOperation: () => import('./CardOperation.vue'),
    OperationDialog: () => import('./OperationDialog.vue')
  }
})
export default class extends Vue {
  private dotationOperations: DotationOperation[] = []

  private dotationOperationSelected: DotationOperation | null = null
  private tempDotationOperation: DotationOperation | null = null

  private dotationOperationSelectedIndex = 0
  private dotationOperationsLoading = true
  private totalDotationOperations = 0

  private showOperationDialog = false

  created() {
    this.getDotationOperations()
  }

  private async getDotationOperations() {
    this.dotationOperationsLoading = true
    this.$emit('loading', true)

    const { data, meta } =
        await DotationOperation
            .page(1)
            .per(100)
            .order({ createdAt: 'desc' })
            .stats({ total: 'count' })
            .all()

    this.dotationOperations = data
    this.totalDotationOperations = meta.stats.total.count

    if (data.length > 0) {
      this.dotationOperationSelected = this.dotationOperations[0]
      this.$emit('selected', {
        operation: this.dotationOperationSelected,
        index: 0
      })
    }
    this.dotationOperationsLoading = false
    this.$emit('loading', false)
  }

  private seletectOperation(operation: DotationOperation, index: number): void {
    this.dotationOperationSelected = operation
    this.dotationOperationSelectedIndex = index
    this.$emit('selected', {
      operation: this.dotationOperationSelected,
      index: this.dotationOperationSelectedIndex
    })
  }

  addInList(operation: DotationOperation): void {
    this.dotationOperations.unshift(operation)
    this.totalDotationOperations++
    this.dotationOperationSelected = operation
    this.dotationOperationSelectedIndex = 0

    this.$emit('selected', {
      operation: this.dotationOperationSelected,
      index: this.dotationOperationSelectedIndex
    })
  }

  updateInList(operation: DotationOperation): void  {
    this.dotationOperations.splice(this.dotationOperationSelectedIndex, 1, operation.dup())
    this.dotationOperationSelected = operation.dup()

    this.$emit('selected', {
      operation: this.dotationOperationSelected,
      index: this.dotationOperationSelectedIndex
    })

    this.tempDotationOperation = null
  }

  public openUpdateDialog(): void {
    this.tempDotationOperation = this.dotationOperationSelected!.dup()
    this.showOperationDialog = true
  }

  public deleteOperation(index: number) {
    this.dotationOperations.splice(index, 1)
    this.totalDotationOperations--
  }
}
