
import { Component, Vue } from 'vue-property-decorator'

import BudgetMove from '@/models/BudgetMove'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'HistoryFundsTable',
  components: {
    HistoryFundsTableFilters: () => import('./components/HistoryFundsTableFilters.vue'),
    Pagination
  }
})
export default class extends Vue {
  private budgetMoves: BudgetMove[] = []
  private totalBudgetMoves = 0
  private budgetMovesLoading = true
  private headersByDate: any[] = []

  filters: any = {
    email: null,
    firstName: null,
    lastName: null,
    dateRange: null,
    sourceType: null,
  }
  pagination = {
    page: 1,
    limit: 10
  }

  created() {
    this.getBudgetMoves()
  }

  private async getBudgetMoves() {
    this.budgetMovesLoading = true

    const createdAtGte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![0])).format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![1])).format('YYYY-MM-DD HH:mm:ss') : ''

    const { data, meta } = await BudgetMove
      .includes([{ source: ['customer', 'dotationOperation', 'multiChoiceGiftCard'] }])
      .where({
        customerFirstname: { prefix:this.filters.firstName },
        customerLastname: { prefix:this.filters.lastName },
        customerEmail: { prefix: this.filters.email },
        sourceType: this.filters.sourceType,
        createdAt: { gte: createdAtGte, lte: createdAtLte }
      })
      .page(this.pagination.page)
      .per(this.pagination.limit)
      .order({ createdAt: 'desc' })
      .stats({ total: 'count' })
      .all()

    this.budgetMoves = data
    this.balanceMovePerDate()
    this.totalBudgetMoves = meta.stats.total.count
    this.budgetMovesLoading = false
  }

  handleSearch(filters: any): void {
    this.filters = filters
    this.pagination.page = 1
    this.budgetMoves = []
    this.getBudgetMoves()
  }

  private sourceName(budgetMove: BudgetMove) {
    if (budgetMove.sourceType === 'BankTransfer') return `#${budgetMove.source.reference}`
    else if (budgetMove.source.customer) return `${budgetMove.source.customer.firstname} ${budgetMove.source.customer.lastname}`
    else  return 'Carte vierge'
  }

  private balanceMovePerDate() {
    this.headersByDate = []
    this.budgetMoves.map((e, index) => {
      if (this.budgetMoves[index - 1] && this.$moment(this.budgetMoves[index - 1].createdAt).format('L') !== this.$moment(e.createdAt).format('L')) {
        this.headersByDate.push(index)
      }
    })
  }

  private setClassTrend(budgetMove: BudgetMove): string {
    if (budgetMove.sourceType === 'BankTransfer') return 'badge-success'
    else return 'badge-debit'
  }

  private setIconTrend(budgetMove: BudgetMove): string {
    if (budgetMove.sourceType === 'BankTransfer') return 'el-icon-top-right'
    else return 'el-icon-bottom-left'
  }
}
