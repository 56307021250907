
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Gifts',
  components: {
    GiftsHeader: () => import('./components/GiftsHeader.vue'),
    HistoryFundsTable: () => import('./components/HistoryFundsTable.vue'),
    OperationsWrapper: () => import('./components/OperationsWrapper/index.vue'),
    GiftCreateForm: () => import('@/components/GiftCreateForm/index.vue'),
    GiftsList: () => import('./components/GiftsList.vue')
  }
})
export default class extends Vue {
  activeName = 'first'
}
